import React, { useRef, useMemo, Suspense, useState } from 'react';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { Box, Sphere, Cylinder, Torus, Html, OrbitControls, Stars } from '@react-three/drei';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faEnvelope } from '@fortawesome/free-solid-svg-icons';

function MovingObject(props) {
  const meshRef = useRef();
  const velocity = useRef({
    x: Math.random() * 0.03 - 0.01,
    y: Math.random() * 0.03 - 0.01,
    z: Math.random() * 0.03 - 0.01
  });

  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.position.x += velocity.current.x;
      meshRef.current.position.y += velocity.current.y;
      meshRef.current.position.z += velocity.current.z;
    }

    // gradually change color
    if (meshRef.current) {
      meshRef.current.rotation.x += Math.random() * 0.01 - 0.005;
      meshRef.current.rotation.y += Math.random() * 0.01 - 0.005;
      meshRef.current.rotation.z += Math.random() * 0.01 - 0.005;
    }
  });

  return <mesh ref={meshRef} {...props} />;
}


function Scene() {
  const cameraRef = useRef();

  const boxPositions = useMemo(() => {
    const positions = [];
    for (let i = 0; i < 10; i++) {
      const x = Math.random() * 20 - 10;
      const y = Math.random() * 20 - 10;
      const z = Math.random() * -30 - 20;
      positions.push([x, y, z]);
    }
    return positions;
  }, []);

  useFrame((state, delta) => {
    if (cameraRef.current) {
      cameraRef.current.position.z += Math.sin(state.clock.getElapsedTime()) * delta * 0.1;
    }
  });

  return (
    <>
      <color attach="background" args={['#111']} />

      <ambientLight intensity={5} />
      <pointLight position={[10, 10, 10]} intensity={1} />
      <directionalLight position={[0, 10, 0]} intensity={1.5} />
      <spotLight
        position={[-10, 10, 10]}
        angle={0.3}
        penumbra={1}
        intensity={2}
        castShadow
      />
      {boxPositions.map((pos, i) => (
        <MovingObject key={i}>
          <boxGeometry args={[1, 1, 1]} />
          <meshStandardMaterial color="black" />
        </MovingObject>
      ))}

      <perspectiveCamera ref={cameraRef} position={[0, 0, 5]} fov={50} />
    </>
  );
}



function App() {
  const [email, setEmail] = useState("");
  const [isSubscribed, setIsSubscribed] = useState("");
  const [isError, setIsError] = useState(false);

  async function subscribe() {
    try {
      const response = await
        fetch("https://tkxh2b2lrl.execute-api.us-east-1.amazonaws.com/prod/subscribe/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: email.trim() }),
          });

      const responseData = await response.json();

      if (!response.ok) {
        setIsSubscribed(responseData.message);
        setIsError(true);
      } else {
        setEmail("");
        console.log(responseData.message)
        setIsSubscribed(responseData.message);
        setIsError(false);
      }
    } catch (error) {
      console.error(error);
      setIsSubscribed(`Failed to subscribe. ${error.message}`);
      setIsError(true);
    }
  }


  return (
    <div className="App">
      <Canvas className="canvas">
        <Suspense fallback={<Html>Loading...</Html>}>
          <Scene />
        </Suspense>
      </Canvas>
      <header className="App-header">
        <h1>OFFCHAINPAY</h1>
        <p>Secure and Seamless Offline Crypto Payments</p>
        <h3>Coming Soon</h3>
        <br />


        <div className='subform'>
          <div className='row'>
            <input type="text" placeholder="Email Address" onChange={e => setEmail(e.target.value)} value={email} />
            <a href="#" className="contact-button" onClick={subscribe}>Subscribe to updates</a>
          </div>
          {isSubscribed && <p className={isError ? "error-text" : "success-text"}>{isSubscribed}</p>}
          <div className="row">
            <a href="/pitch-deck.pdf" className="contact-button" style={{marginRight: '8px'}}>
              <FontAwesomeIcon icon={faFile} style={{ marginRight: '8px' }} />
              Pitch Deck
            </a>
            <div className='mr-5 ml-5'/>
            <a href="mailto:hello@offchainpay.app" class="contact-button">
            <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '8px' }} />
              Contact Us</a>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
